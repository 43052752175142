import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { clientPaths, publicPaths } from '../util/frontend';
import PressPlayBibleHome from "./PressPlayBibleHome";

export const pageTransitionStyles = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
}

const AnimatedRoutes = () => {
    return (
        <AnimatePresence>
            <Routes>
                <Route
                    path="/"
                    element={<Navigate to={publicPaths.pressPlayBiblePath} />}
                />
                <Route
                    path={publicPaths.pressPlayBiblePath}
                    element={<PressPlayBibleHome />}
                />
                {/* Other routes can go here */}
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes;