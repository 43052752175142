/**
 * Page providing a centralized naming convention for frontend routes
 */

const publicBaseUrl = "";
const clientBaseUrl     = "/client"
const pressPlayBiblePath    = "/press-play-bible";

export const publicPaths = {
    landingPagePath             : `${publicBaseUrl}/`,
    aboutPath                   : `${publicBaseUrl}/about`,
    contactPath                 : `${publicBaseUrl}/contact`,
    registerPath                : `${publicBaseUrl}/register`,
    messageSentPath             : `${publicBaseUrl}/messagesent`,
    pressPlayBiblePath,
}

export const clientPaths = {
    homePath                                : `${clientBaseUrl}`,
    loginPath                               : `${clientBaseUrl}/login`,
    registerPath                            : `${clientBaseUrl}/register`,
    unauthorizedPath                        : `${clientBaseUrl}/unauthorized`,
    registrationSuccessPath                 : `${clientBaseUrl}/registrationsuccess`,
    verificationSuccessPath                 : `${clientBaseUrl}/verificationsuccess`,
    requestLoanPath                         : `${clientBaseUrl}/requestloan`,
    loanDetailsPath                         : `${clientBaseUrl}/loandetails/:loanId`,
    currentLoansListPath                    : `${clientBaseUrl}/currentloanslist`,
    settledLoansListPath                    : `${clientBaseUrl}/settledloanslist`,
    loanRequestSubmittedPath                : `${clientBaseUrl}/loanrequestsubmitted`,
    moreInputsPath                          : `${clientBaseUrl}/moreinputs`,
    loansByClientPath                       : `${clientBaseUrl}/loansbyclient/:clientId`,
    loanRequestsPath                        : `${clientBaseUrl}/loanrequests`,
    clientDetailsPath                       : `${clientBaseUrl}/clientdetails/:clientId`,
    makePaymentPath                         : `${clientBaseUrl}/makepayment`,
    paymentsListPath                        : `${clientBaseUrl}/paymentslist`,
    paymentsByLoanPath                      : `${clientBaseUrl}/paymentsbyloan/:loanId`,
    paymentDetailsPath                      : `${clientBaseUrl}/paymentdetails/:paymentId`,
    loanRequestDetailsPath                  : `${clientBaseUrl}/loanrequestdetails/:loanRequestId`,
    accountInactivePath                     : `${clientBaseUrl}/accountinactive`,
}
