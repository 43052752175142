import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true}
    }

    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      console.log("Here is my error : ", error);
    }

    render() {
      if (this.state.hasError) {
        return this.props.fallback;
      }
      return this.props.children;
    }
  }

export default ErrorBoundary;
