import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedRoutes from './components/AnimatedRoutes';
import ErrorBoundary from './components/ErrorBoundary';
import { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setItem } from './features/auth/authSlice';
import { darkColor } from "./util/initials";

const theme = createTheme({
  palette: {
    primary: {
      main: darkColor,
    },
    mode: 'light', // Set the mode to dark if you want a dark theme
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: darkColor, // Replace with your desired color
        },
      },
    },
  },
});

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 768) {
        dispatch(setItem({ key: "sidebarCollapsed", value: false }));
      } else {
        dispatch(setItem({ key: "sidebarCollapsed", value: true }));
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  return (
    <ErrorBoundary fallback="Error">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AnimatedRoutes />
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
